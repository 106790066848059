import * as React from 'react';
import '../../../components/Search/SearchResults/SearchResults.css';
import Checkbox from '../../CheckBox/ChechBox';

interface IFundingResultsProps {
  data: any;
  index: number;
  onToggle: (reference: string, isChecked: boolean) => void;
  isSelected: boolean;
  showCheckbox: boolean;
}

export const FundingResults: React.FC<IFundingResultsProps> = ({ index, data, onToggle, isSelected, showCheckbox }) => {
  const isTrialUser = localStorage.getItem('isTrialUser') === "true";
  return (
    <div className="search-results--row">
      {!isTrialUser ? (
        <>
          <div className="search-results--first-column">
            <div className="search-results--result-name">{data?.status[0] == "31094504" ? (`[DRAFT] ${data?.identifier || "No Identifer"} ${data?.callTitle[0] || "No Title"}`) : (`${data?.identifier || "No Identifer"} ${data?.callTitle[0] || "No Title"}`)}</div>
            <div className="search-results--result-description">{data?.summary || "No summary"}</div>
          </div>
          {showCheckbox && data?.status[0] === "31094504" && (
            <div className='custom-delete-checkbox'>
              <Checkbox
                label='Add to delete'
                checked={isSelected}
                onChange={(checked: boolean) => onToggle(data?.reference, checked)} 
              />
            </div>
          )}
        </>
      ) : (
        <div className="search-results--first-column">
          {isTrialUser && index < 3 ? (
            <div>
              <div className="search-results--result-name">{data?.status[0] == "31094504" ? (`[DRAFT] ${data?.identifier || "Identifier not yet published"} ${data?.callTitle[0] || "Title not yet published"}`) : (`${data?.identifier || "Identifier not yet published"} ${data?.callTitle[0] || "Title not yet published"}`)}</div>
              <div className="search-results--result-description">{data?.summary || "Summary not yet published"}</div>
            </div>
          ) : (
            <div className="search-results--subscribe">
              <h1>
                {'To see result details and unlock share feature please '}
                <a className="" target="blank" href="https://spinbase.eu/pricing/">
                  {'upgrade your subscription'}
                </a>
              </h1>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
