import * as React from 'react';
import Filter from './Filters/Filter';
import { FundingResults } from './FundingResults/FundingResults';
import { store } from '../../store';
import { useSelector } from 'react-redux';
import { Spinner } from '@fluentui/react/lib/Spinner';
import './FundingsPage.css';
import {
  searchRequestFundings,
  deleteSelectedFundings,
  uploadFundingData,
  clearUploadFundingData,
} from '../../app/Fundings/actions';
import IconArrowRigh from '../../assets/icon--arrow--right.svg';
import IconArrowLeft from '../../assets/icon--arrow--left2.svg';
import { MessageBar, MessageBarType } from '@fluentui/react';
import { Dialogbox } from '../Dialogbox/Dialogbox';


const Fundings: React.FC = () => {
  const isExporting = useSelector((state: any) => state.funding.isExporting);
  const isDeleting = useSelector((state: any) => state.funding.isDeleting);
  const fundingResults = useSelector((state: any) => state.funding.results);
  const loading = useSelector((state: any) => state.funding.loading);
  const currentFrameworkProgramme = useSelector((state: any) => state.funding.frameworkProgramme);
  const currentStatus = useSelector((state: any) => state.funding.status);
  const isUploading = useSelector((state: any) => state.funding.isUploading);
  const uploadingError = useSelector((state: any) => state.funding.uploadingError);
  const uploadingResponse = useSelector((state: any) => state.funding.uploadingResponse);
  const limit = 10;
  const currentPage = useSelector((state: any) => state.funding.currentPage);
  const totalPages = useSelector((state: any) => state.funding.totalPage);
  const isSearchRequestError = useSelector((state: any) => state.funding.isSearchRequestError);
  const isExportRequestError = useSelector((state: any) => state.funding.isExportRequestError);
  const [toastMessage, setToastMessage] = React.useState<string | null>(null);
  const [toastType, setToastType] = React.useState<MessageBarType | undefined>(undefined);
  const [selectedReferences, setSelectedReferences] = React.useState<string[]>([]);
  

  const [isDialogVisible, setIsDialogVisible] = React.useState(false);
  const dialogContentProps = {
    type: 'delete',
    title: 'Confirm Deletion',
    closeButtonAriaLabel: 'Close',
    subText: `Are you sure you want to delete ${selectedReferences.length} selected funding(s)?`,
  };

  const modelProps = {
    isBlocking: true,
    styles: { main: { maxWidth: 450 } },
  };

  // Track if deletion was just completed
  const [justDeleted, setJustDeleted] = React.useState(false);

  const showToast = (message: string, type: MessageBarType, duration = 10000) => {
    setToastMessage(message);
    setToastType(type);
    setTimeout(() => {
      setToastMessage(null);
    }, duration);
  };

  React.useEffect(() => {
    if (isSearchRequestError) {
      showToast('An error occurred while fetching results. Please refresh', MessageBarType.error, 10000);
    }
    if (isExportRequestError) {
      showToast('An error occured while exporting data. Please refresh', MessageBarType.error, 10000);
    }
    if (uploadingError) {
      showToast('Failed to upload file. Please try again', MessageBarType.error, 10000);
    }
  }, [isExportRequestError, isSearchRequestError, uploadingError]);

  const refetchData = () => {
    store.dispatch(
      searchRequestFundings({
        frameworkProgramme: currentFrameworkProgramme,
        status: currentStatus,
        limit: limit,
        offset: (currentPage - 1) * limit,
      }),
    );
  };

  React.useEffect(() => {
    if (justDeleted && !isDeleting) {
      refetchData();
      setJustDeleted(false);
    }
    if (uploadingError !== null) {
      showToast('Failed to upload file. Please try again', MessageBarType.error);
    }
  }, [isDeleting, justDeleted, currentFrameworkProgramme, currentStatus, currentPage, uploadingError]);

  React.useEffect(() => {
    if (uploadingResponse) {
      if (uploadingResponse?.statusCode === 201) {
        showToast('File uploaded successfully', MessageBarType.success);
        refetchData();
      } else {
        showToast(
          `Failed to upload file. Please try again ${uploadingResponse?.error}, ${uploadingResponse?.details}`,
          MessageBarType.error,
        );
      }
      store.dispatch(clearUploadFundingData());
    }
  }, [uploadingResponse]);

  const handlePageChange = (newPage: number) => {
    if (newPage < 1 || newPage > totalPages) {
      return;
    }
    const offset: number = (newPage - 1) * limit;
    store.dispatch(
      searchRequestFundings({
        frameworkProgramme: currentFrameworkProgramme,
        status: currentStatus,
        limit: limit,
        offset: offset,
      }),
    );
  };

  const handleToggleReference = (reference: string, isChecked: boolean) => {
    setSelectedReferences((prev) =>
      isChecked ? [...prev, reference] : prev.filter((ref) => ref !== reference),
    );
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target;
    const file = fileInput?.files?.[0];

    if (file) {
      const formData = new FormData();
      formData.append('files', file);
      store.dispatch(uploadFundingData({ formData }));
      fileInput.value = '';
    }
  };

  const handleDeleteFundingClose = () => {
    setIsDialogVisible(false);
  };

  const handleDeleteSelected = () => {
    store.dispatch(deleteSelectedFundings({ references: selectedReferences }));
    setJustDeleted(true);
    setSelectedReferences([]);
    setIsDialogVisible(false);
  };

  return (
    <div className="search-page">
      {toastMessage && (
        <div className="message-bar">
          <MessageBar
            messageBarType={toastType}
            isMultiline={true}
            onDismiss={() => setToastMessage(null)}
            dismissButtonAriaLabel="Close"
          >
            {toastMessage}
          </MessageBar>
        </div>
      )}
      <input
        type="file"
        accept=".csv, .xlsx"
        onChange={handleFileUpload}
        style={{ display: 'none' }}
        id="file-upload"
      />
      <Dialogbox
        dialogContentProps={dialogContentProps}
        modalProps={modelProps}
        hideDialog={!isDialogVisible}
        onDismiss={handleDeleteFundingClose}
        pbOnClick={handleDeleteSelected}
        pbText="Delete"
        dbOnClick={handleDeleteFundingClose}
        dbText="Cancel"
      />
      <div className="container result">
        <div className="aside">
          <div className="filter-sticky-container">
            <Filter />
          </div>
        </div>
        <div
          className="main"
          style={isExporting || isUploading ? { opacity: 0.75, pointerEvents: 'none' } : {}}
        >
          <div className="search-results--container">
            <div className="search-results--content">
              {loading && !isSearchRequestError && (
                <div className="spinner-container">
                  <Spinner />
                </div>
              )}
              {!loading && !isSearchRequestError && fundingResults.length > 0
                ? fundingResults.map((result: any, index: number) => (
                    <FundingResults
                      key={`${result.reference}`}
                      index={index}
                      data={result}
                      onToggle={handleToggleReference}
                      isSelected={selectedReferences.includes(result.reference)}
                      showCheckbox={false}
                    />
                  ))
                : !loading && <p className="no-data">No data to show</p>}
            </div>
          </div>
          {!loading && !isSearchRequestError && fundingResults.length > 0 && (
            <div className="pagination">
              <div className="pagination-button">
                <img src={IconArrowLeft} alt="previous" onClick={() => handlePageChange(currentPage - 1)} />
              </div>
              <span>
                Page {currentPage} of {totalPages}
              </span>
              <div className="pagination-button">
                <img src={IconArrowRigh} alt="next" onClick={() => handlePageChange(currentPage + 1)} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const FundingsPage = Fundings;
