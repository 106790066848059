import * as React from 'react';

import { Spinner } from '@fluentui/react/lib/Spinner';
import { connect } from 'react-redux';

import {
  addNationalFilterCondition,
  clearNationalFilter,
  nationalSearchRequest,
  removeNationalFilterCondition,
  setNationalOrderCondition,
} from '../../../../app/NationalSearch/actions';
import { INationalSearchState } from '../../../../app/NationalSearch/reducer';
import { IPartnerSearchState } from '../../../../app/PartnerSearch/reducer';
import { IProjectsState } from '../../../../app/Projects/reducer';
import {
  addFilterCondition,
  clearFilter,
  removeFilterCondition,
  searchRequest,
  selectableTabs,
  setOrderCondition,
} from '../../../../app/Search/actions';
import { ISearchState } from '../../../../app/Search/reducer';
import { IState } from '../../../../reducers';
import { store } from '../../../../store';
import GroupEUTopicsActions from '../GroupActions/GroupActionsEUTopics';
import GroupActionsNationalTopics from '../GroupActions/GroupActionsNationalTopics';
import { CheckboxGroup } from './CheckboxGroup';
import './Filter.css';
import { RadioGroup } from './RadioGroup';

interface IPropsFromState {
  searchResults: ISearchState;
  nationalSearchResults: INationalSearchState;
  partners: IPartnerSearchState;
  projects: IProjectsState;
}

interface IFilter {
  fundingFilterableItems: any[];
  nationalFilterableItems: any[];
  partnersFilterableItems: any[];
  projectsFilterableItems: any[];
  openFilterIndex: number;
}

const statuses: any[] = [
  { key: 'topic_status', value: 'Forthcoming', name: 'Forthcoming' },
  { key: 'topic_status', value: 'Draft', name: 'Draft' },
  { key: 'topic_status', value: 'Open', name: 'Open' },
  { key: 'topic_status', value: 'Closed', name: 'Closed' },
];
const defaultStatuses = ['Open', 'Draft', 'Forthcoming'];

const sources: any[] = [
  { key: 'source_name', value: 'Research.fi', name: 'Research.fi' },
  { key: 'source_name', value: 'Business Finland', name: 'Business Finland' },
];
const defaultSources = ['Research.fi', 'Business Finland'];

class Filter extends React.Component<IPropsFromState, IFilter> {
  constructor(props: IPropsFromState) {
    super(props);

    this.handleFilter = this.handleFilter.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.search = this.search.bind(this);
    this.handleNationalSearchFilter = this.handleNationalSearchFilter.bind(this);
    this.isNationalSelected = this.isNationalSelected.bind(this);
    this.filtersChanged = this.filtersChanged.bind(this);
    this.clearFilter = this.clearFilter.bind(this);

    this.state = {
      fundingFilterableItems: [
        { value: 'type_of_action', name: 'Type of action' },
        { value: 'call_programme_description', name: 'Call programme' },
        {
          value: 'topic_status',
          name: 'Topic status',
          items: statuses,
        },
      ],
      nationalFilterableItems: [{ value: 'source_name', name: 'Source name', items: sources }],
      partnersFilterableItems: [
        { value: 'country', name: 'Country' },
        { value: 'activityType', name: 'Type' },
        { value: 'role', name: 'Role' },
      ],
      projectsFilterableItems: [
        { value: 'call', name: 'Call' },
        { value: 'coordinatorCountry', name: 'Coordinator country' },
      ],
      openFilterIndex: -1,
    };
  }

  clearFilter() {
    if (this.props.searchResults.selectedTab === selectableTabs.FUNDING) {
      store.dispatch(clearFilter());
      this.search(defaultStatuses);
    }

    if (this.props.searchResults.selectedTab === selectableTabs.NATIONAL) {
      store.dispatch(clearNationalFilter());
      this.nationalSearch(defaultSources);
    }
  }

  handleSearchOrder(item: any) {
    store.dispatch(setOrderCondition(item));
  }

  search(getCurrentCallStatuses: string[]) {
    store.dispatch(
      searchRequest({
        statuses: getCurrentCallStatuses,
        query: this.props.searchResults.query,
        exclude: [],
        limit: 30,
      }),
    );
  }

  handleNationalSearchOrder(item: any) {
    store.dispatch(setNationalOrderCondition(item));
  }

  nationalSearch(getCurrentCallSourceNames: string[]) {
    store.dispatch(
      nationalSearchRequest({
        source_name: getCurrentCallSourceNames,
        query: this.props.searchResults.query,
        exclude: [],
        limit: 30,
      }),
    );
  }

  handleFilter(item: any) {
    if (this.isSelected(item)) {
      store.dispatch(removeFilterCondition(item.key, item.value));
    } else {
      store.dispatch(addFilterCondition(item.key, item.value));
    }

    if (item.key === 'topic_status') {
      let showCallStatusArray = [
        ...this.props.searchResults.filterConditions
          .filter((filter) => filter.key === 'topic_status')
          .map(({ value }: any) => value),
      ];

      if (this.isSelected(item)) {
        showCallStatusArray = showCallStatusArray.filter((status) => status !== item.value);
      } else {
        showCallStatusArray.push(item.value);
      }

      this.search(showCallStatusArray);
    }
  }

  isSelected(item: any) {
    const { filterConditions } = this.props.searchResults;
    return (
      filterConditions.filter((condition) => condition.value === item.value && condition.key === item.key)
        .length > 0
    );
  }

  handleNationalSearchFilter(item: any) {
    if (this.isNationalSelected(item)) {
      store.dispatch(removeNationalFilterCondition(item.key, item.value));
    } else {
      store.dispatch(addNationalFilterCondition(item.key, item.value));
    }

    if (item.key === 'source_name') {
      let showCallSourceNameArray = [
        ...this.props.nationalSearchResults.filterConditions
          .filter((filter) => filter.key === 'source_name')
          .map(({ value }: any) => value),
      ];

      if (this.isNationalSelected(item)) {
        showCallSourceNameArray = showCallSourceNameArray.filter((sourceName) => sourceName !== item.value);
      } else {
        showCallSourceNameArray.push(item.value);
      }

      this.nationalSearch(showCallSourceNameArray);
    }
  }

  isNationalSelected(item: any) {
    const { filterConditions } = this.props.nationalSearchResults;
    return (
      filterConditions.filter((condition) => condition.value === item.value && condition.key === item.key)
        .length > 0
    );
  }

  getDistinctValues(key: string) {
    let results: any[];
    const { selectedTab } = this.props.searchResults;

    if (selectedTab === selectableTabs.FUNDING) {
      results = this.props.searchResults.results;
    } else if (selectedTab === selectableTabs.NATIONAL) {
      results = this.props.nationalSearchResults.results;
    } else if (selectedTab === selectableTabs.PARTNERS) {
      results = this.props.partners.results;
    } else {
      results = this.props.projects.results;
    }

    const keys = results.reduce((array: [], item: any) => {
      let filteredItems;
      const getSelectedTabValue = this.props.searchResults.selectedTab;
      if (getSelectedTabValue === selectableTabs.FUNDING || getSelectedTabValue === selectableTabs.NATIONAL) {
        filteredItems = item.metadata[key] ? item.metadata[key].split(';') : [];
      } else {
        filteredItems = item[key] ? item[key].split(';') : [];
      }
      return [...array, ...filteredItems];
    }, []);

    const set = [...new Set(keys)];
    const objects = set.map((value) => ({ key, value, name: value }));
    return objects.filter((object) => object.value !== null && object.value !== '');
  }

  filtersChanged() {
    const searchResults = this.props.searchResults;
    const nationalSearchResults = this.props.nationalSearchResults;

    switch (searchResults.selectedTab) {
      case selectableTabs.FUNDING:
        return (
          // Has other than the initial filters set
          searchResults.filterConditions.filter((filter) => {
            return (
              filter.key !== 'topic_status' || ['Forthcoming', 'Draft', 'Open'].indexOf(filter.value) === -1
            );
          }).length > 0 ||
          // Has removed some of the initial filters
          !['Forthcoming', 'Draft', 'Open'].every((status) =>
            searchResults.filterConditions.some(
              (filter) => filter.key === 'topic_status' && filter.value === status,
            ),
          )
        );
      case selectableTabs.NATIONAL:
        return (
          // Has other than the initial filters set
          nationalSearchResults.filterConditions.filter((filter) => {
            return (
              filter.key !== 'source_name' || ['Research.fi', 'Business Finland'].indexOf(filter.value) === -1
            );
          }).length > 0 ||
          // Has removed some of the initial filters
          !['Research.fi', 'Business Finland'].every((sourceName) =>
            nationalSearchResults.filterConditions.some(
              (filter) => filter.key === 'source_name' && filter.value === sourceName,
            ),
          )
        );
      case selectableTabs.PARTNERS:
      case selectableTabs.PROJECTS:
      default:
        return searchResults.filterConditions.length > 0 || nationalSearchResults.filterConditions.length > 0;
    }
  }

  render() {
    const searchResults = this.props.searchResults;
    const nationalSearchResults = this.props.nationalSearchResults;
    const {
      fundingFilterableItems,
      nationalFilterableItems,
      partnersFilterableItems,
      projectsFilterableItems,
    } = this.state;

    let filterableItems: any[];
    if (searchResults.selectedTab === selectableTabs.FUNDING) {
      filterableItems = fundingFilterableItems;
    } else if (searchResults.selectedTab === selectableTabs.NATIONAL) {
      filterableItems = nationalFilterableItems;
    } else if (searchResults.selectedTab === selectableTabs.PARTNERS) {
      filterableItems = partnersFilterableItems;
    } else {
      filterableItems = projectsFilterableItems;
    }

    // prettier-ignore
    const orderByOptions = [
      { label: 'Match',    value: 'similarity',     key: 'similarity'     },
      { label: 'Deadline', value: 'deadline1',      key: 'deadline1'      },
      { label: 'Budget',   value: 'budget_numbers', key: 'budget_numbers' },
      { label: 'TRL',      value: 'trl_text',       key: 'trl_text'       },
    ];

    // prettier-ignore
    const orderByNationalOptions = [
      // { label: 'Match',        value: 'similarity',    key: 'similarity' },
      { label: 'Deadline date',     value: 'deadline_date', key: 'deadline_date' },
      { label: 'Opening date', value: 'opening_date',  key: 'opening_date' },
    ];

    const filters = filterableItems.map(({ name, value, items }) => {
      const options: string[] = items ? items : this.getDistinctValues(value);

      if (searchResults.selectedTab === selectableTabs.NATIONAL) {
        return {
          label: name,
          value: nationalSearchResults.filterConditions.reduce((acc, condition) => {
            if (condition.key === value) {
              acc.push(condition.value);
            }
            return acc;
          }, []),
          options: options.map((getOption: any) => ({
            label: getOption.name,
            value: getOption.value,
            key: getOption.key,
          })),
        };
      }

      return {
        label: name,
        value: searchResults.filterConditions.reduce((acc, condition) => {
          if (condition.key === value) {
            acc.push(condition.value);
          }
          return acc;
        }, []),
        options: options.map((getOption: any) => ({
          label: getOption.name,
          value: getOption.value,
          key: getOption.key,
        })),
      };
    });

    return (
      <div
        className="result-filter__container"
        style={searchResults.isFetching ? { opacity: 0.75, pointerEvents: 'none' } : {}}
      >
        <div className="result-filter">
          <div className="result-filter__filter-group">
            {searchResults.selectedTab === selectableTabs.FUNDING && (
              <>
                <p className="filter-group-label">Order by</p>
                <RadioGroup
                  name="order-by"
                  options={orderByOptions}
                  value={searchResults.orderCondition.value}
                  onChange={(value) => {
                    const option = orderByOptions.find(
                      (getCurrentOrderBy) => getCurrentOrderBy.value === value,
                    );
                    this.handleSearchOrder(option);
                  }}
                />
              </>
            )}

            {searchResults.selectedTab === selectableTabs.NATIONAL && (
              <>
                <p className="filter-group-label">Order by</p>
                <RadioGroup
                  name="order-by"
                  options={orderByNationalOptions}
                  value={nationalSearchResults.orderCondition.value}
                  onChange={(value) => {
                    const option = orderByNationalOptions.find(
                      (getCurrentOrderBy) => getCurrentOrderBy.value === value,
                    );
                    this.handleNationalSearchOrder(option);
                  }}
                />
              </>
            )}

            <p className="filter-group-label">Filter by</p>

            {filters.map((filter) => (
              <CheckboxGroup
                label={filter.label}
                name={filter.label}
                options={filter.options}
                value={filter.value}
                key={filter.label + '_' + filter.value}
                hasShowMore={filter.label !== 'Topic status'}
                onChange={(value) => {
                  const option = filter.options.find(
                    (getSelectedCallStatus: any) => getSelectedCallStatus.value === value,
                  );
                  searchResults.selectedTab === selectableTabs.NATIONAL
                    ? this.handleNationalSearchFilter(option)
                    : this.handleFilter(option);
                }}
              />
            ))}

            {this.filtersChanged() ? (
              <div className="result-filter__filter-item">
                <button className="result-filter__clear-button" onClick={this.clearFilter}>
                  Reset filters
                </button>
              </div>
            ) : null}

            {(searchResults.isFetching || nationalSearchResults.isFetching) && (
              <div className="spinner-wrapper">
                <Spinner />
              </div>
            )}
          </div>

          {searchResults.selectedTab === selectableTabs.FUNDING && (
            <div className="result-filter__group-actions">
              <GroupEUTopicsActions />
            </div>
          )}

          {searchResults.selectedTab === selectableTabs.NATIONAL && (
            <div className="result-filter__group-actions">
              <GroupActionsNationalTopics />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ searchResults, nationalSearchResults, partners, projects }: IState) => {
  return { searchResults, nationalSearchResults, partners, projects };
};

export default connect(mapStateToProps)(Filter);
