import { takeEvery, call, put } from 'redux-saga/effects';
import callApi from '../../utils/callApi';
import callApiFormData from '../../utils/callApiFormData';
import { FundingActionTypes, fetchFundings, fetchFundingByReference, fetchExports, setSearchRequestFailed, setSearchRequestReferenceFailed, setExportRequestError, deleteSelectedFundingsSuccess, uploadFundingDataSuccess, uploadFundingDataFailed } from './actions';

function* handleSearchRequestFundingReference(action: any): any {
  try {
    const {reference} = action.payload;
    const res = yield call(callApi, 'get', process.env.REACT_APP_SEARCH_API_URL || '', `/v1/fundings/${reference}`);
    yield put (fetchFundingByReference(res));
  } catch (err) {
    console.error(err);
    yield put(setSearchRequestReferenceFailed(true));
  }
}

function* handleSearchRequestFundings(action: any): any {
  try {
    const { frameworkProgramme, status, limit, offset } = action.payload;
    const pathParams = new URLSearchParams();
    pathParams.append('frameworkProgramme[]', frameworkProgramme);
    pathParams.append('status[]', status);
    pathParams.append('limit', limit.toString());
    pathParams.append('offset', offset.toString());
    
    const res = yield call(callApi, 'get', process.env.REACT_APP_SEARCH_API_URL || '', `/v1/fundings?${pathParams.toString()}`);
    yield put(fetchFundings(res.data, res.totalPages, res.totalRecords, res.currentPage, res.pageSize, frameworkProgramme, status));
  } catch (err) {
    console.error(err);
    yield put(setSearchRequestFailed(true));
  }
}

function* handleExportRequest(action: any): any {
  try {
    const {frameworkProgramme, status} = action.payload;
    const pathParams = new URLSearchParams();
    pathParams.append('frameworkProgramme[]', frameworkProgramme);
    pathParams.append('status[]', status);

    const res = yield call(callApi, 'get', process.env.REACT_APP_SEARCH_API_URL || '', `/v1/fundings/exportall?${pathParams.toString()}`);
    yield put(fetchExports(res));
    
  } catch (err) {
    console.error(err);
    yield put(setExportRequestError(true, false));
  }
}

function* handleDeleteSelectedFundings(action: any): any {
  try {
    const {references} = action.payload;
    const pathParams = new URLSearchParams();
    pathParams.append('references[]', references);
    yield call(callApi, 'delete', process.env.REACT_APP_SEARCH_API_URL || '', `/v1/fundings?${pathParams.toString()}`);
    yield put(deleteSelectedFundingsSuccess());
  } catch (err) {
    console.error(err);
    yield put(setSearchRequestFailed(true));
  }
}

function* handleUploadFundingData(action: any): any {
  try {
    const { formData } = action.payload;
    const res = yield call(callApiFormData, 'post', process.env.REACT_APP_SEARCH_API_URL || '', '/v1/fundings/upload', formData);
    yield put(uploadFundingDataSuccess(res));
  } catch (err) {
    console.error(err);
    yield put(uploadFundingDataFailed(err));
  }
}

export default function* fundingSaga() {
  yield takeEvery(FundingActionTypes.EXPORT_REQUEST, handleExportRequest);
  yield takeEvery(FundingActionTypes.SEARCH_REQUEST, handleSearchRequestFundings);
  yield takeEvery(FundingActionTypes.SEARCH_REQUEST_REFERENCE, handleSearchRequestFundingReference);
  yield takeEvery(FundingActionTypes.DELETE_SELECTED_FUNDINGS, handleDeleteSelectedFundings);
  yield takeEvery(FundingActionTypes.UPLOAD_FUNDING_DATA, handleUploadFundingData);
}
