import * as React from 'react';
import { Dialog, DialogFooter, PrimaryButton, DefaultButton } from '@fluentui/react';

interface DialogboxProps {
  hideDialog: boolean;
  onDismiss: () => void;
  dialogContentProps: any;
  modalProps: any;
  pbOnClick: () => void;
  pbText: string;
  dbOnClick: () => void;
  dbText: string;
}

export const Dialogbox: React.FC<DialogboxProps> = ({hideDialog, onDismiss, dialogContentProps, modalProps, pbOnClick, pbText, dbOnClick, dbText}) => {
  return (
    <div>
      <Dialog
        hidden={hideDialog}
        onDismiss={onDismiss}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={pbOnClick} text={pbText} />
          <DefaultButton onClick={dbOnClick} text={dbText} />
        </DialogFooter>
      </Dialog>
    </div>
  );
};